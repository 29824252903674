<script>
import Layout from "@/layouts/main.vue"
import PageHeader from "@/components/page-header.vue";
import NotFound from "@/components/not-found.vue";
import DataLoader from "@/components/data-loader.vue";
import MemberBasicInformation from "@/components/dashboard/user/member/member-basic-information.vue";
import API from "@/plugins/axios-method";
import UserAuthorisedPhoneNumber from "@/components/dashboard/user/user-authorised-phone-number.vue";
import ReferralInformations from "@/components/dashboard/user/referral-informations.vue";
import MemberAccountsInformation from "@/components/dashboard/user/member/member-accounts-information.vue";
export default {
  name: "single-member",
  components: {
    MemberAccountsInformation,
    ReferralInformations,
    UserAuthorisedPhoneNumber, MemberBasicInformation, DataLoader, NotFound, PageHeader, Layout},
  data(){
    return {
      user: null,
      showDataLoader: true,
      notFound: false,
    }
  },
  mounted() {
    this.getSingleMember()
  },
  methods: {
    getSingleMember(){
      API.get('admin/users/' + this.$route.params.id)
          .then(response => {
            this.user = response.data
          })
          .catch(e => {
            if(e.response.status === 404){
              this.notFound = true
            }
          })
          .finally(() => {
            this.showDataLoader = false
          })
    }
  },
  computed: {
    canShowData(){
      return !this.showDataLoader && this.user
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader
        :title="'Compte numéro ' +this.$route.params.id"
        :show-search="false"
        :show-add-button="false"
        v-if="canShowData"
    />
    <DataLoader v-if="showDataLoader"/>
    <NotFound v-if="!showDataLoader && notFound"/>
    <section v-if="canShowData">
      <b-row>
        <b-col md="4">
          <member-basic-information :user="user" />
        </b-col>
        <b-col md="4">
          <user-authorised-phone-number :user="user" />
        </b-col>
        <b-col md="4">
          <referral-informations
              v-if="user.referralInformations"
              :referral="user.referralInformations"
              :role-title="'Commercial'"
          />
        </b-col>
      </b-row>
    </section>
    <section v-if="user?.user">
      <b-row>
        <b-col md="3" v-for="(a, index) in user.accounts" :key="index">
          <member-accounts-information :account="a" />
        </b-col>
      </b-row>
    </section>
  </Layout>
</template>

<style scoped>

</style>